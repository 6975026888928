import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CommondiagCommonobj } from '../model/CommondiagCommonobj.model';
import { CommondiagCommonobjEnfant } from '../model/CommondiagCommonobjEnfant.model';
import { Context } from '../shared/services/context.service';

@Injectable({
  providedIn: 'root'
})
export class CommondiagCommonobjEnfantService {

  constructor(private http: HttpClient, public platform: Platform) {}
  getObjectifsFromDiagnosticsEnfant(id) {
    return this.http.get<CommondiagCommonobjEnfant[]>(
      Context.apiUrl + "getCommonObjectifsEnfant/" + id
    );
  }
  getObjectifSelonID(id){
    return this.http.get<CommondiagCommonobj[]>(Context.apiUrl+"getObjectifById/" + id);
  }
}
