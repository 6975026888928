import { Context } from "src/app/shared/services/context.service";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MiniPtsService {

  constructor(private http: HttpClient, public platform: Platform) {}

  getallPoints(id){
    return this.http.get(Context.apiUrl+'getMinipoints/'+id);
  }
  addnewPoints(pts){
    return this.http.post(Context.apiUrl + 'Minipoints/insert',pts);
  }
  updateocclD(id,pts){
    return this.http.put(Context.apiUrl+ 'Minipoints/updateoccluD/'+id,pts)
  }
  updateocclF(id,pts){
    return this.http.put(Context.apiUrl+ 'Minipoints/updateoccluF/'+id,pts)
  }
  updateocclG(id,pts){
    return this.http.put(Context.apiUrl+ 'Minipoints/updateoccluG/'+id,pts)
  }
  updateradio(id,pts){
    return this.http.put(Context.apiUrl+ 'Minipoints/updateradio/'+id,pts)
  }

}
