import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Patient} from '../model/model.patient';
import {Context} from '../shared/services/context.service';
import { PatientForStatCorres } from '../model/PatientForStatCorres.model';

@Injectable({
    providedIn: 'root'
})
export class PatientService {
    chercher(value: any) {
      throw new Error('Method not implemented.');
    }

    constructor(private http: HttpClient) {

    }

    AddPatient(patient) {
        console.log(patient);
        return this.http.post(Context.apiUrl + 'addpatient', patient);
    }

    getPatientById(id_personne) {
        // console.log("helo", id_personne)
        return this.http.get<Patient>(Context.apiUrl + 'patient/' + id_personne);
    }

    

    getAllPatientsPagination() {
        return this.http.get<Patient>(Context.apiUrl + 'patient/get_all_patient_Pagination');
    }

    getPatientsREcoByCorrespondantNew(dateDebut, dateFin, idCorrespondant) {
        console.log(dateDebut, dateFin, idCorrespondant);
        return this.http.get<PatientForStatCorres[]>(Context.apiUrl + 'getPatientsREcoByCorrespondantNew/' + dateDebut + '&' + dateFin + '&' + idCorrespondant);
    }

    getallPatient() {
        return this.http.get<Patient>(Context.apiUrl + 'patient/get_all_patient');
    }

    getallPatientByIdPraticien(id) {
        return this.http.get<Patient>(Context.apiUrl + 'get_all_patient_per/'+id);
    }

    GetNextNumDossier() {
        return this.http.get<Patient>(Context.apiUrl + 'GetNextNumDossier');
    }
    updateReglement(id,Patient){
        return this.http.put(Context.apiUrl + 'patient/updatereglement/'+id,Patient)
      }
}
